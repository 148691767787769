// React
import React from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import s from 'src/styles';

// Packages
import { useGlobal } from 'reactn';
import { useResizeObserver } from 'use-events';

// Utilities
import { localizeLink } from 'utilities';
import { transitionNavigate } from 'transition';
import { useCart } from 'hooks';

// Components
import Button from 'components/button';
import Image from 'components/image';

const PromotionalProductComponentInner = ({
    leaveScrollingMode = () => {},
    pageContext,
    product,
    overwrittenSubtitle = null,
    location,
    state,
}) => {
    const { labels, locale, allSwellProducts } = pageContext;

    // Get theme
    const theme = s.themeColor(product.theme);

    // Hook: Cart
    const { addItem, loading, cartUtils } = useCart();

    // Currency
    const [currency] = useGlobal('currency');

    // Cookie shop
    const [cookieShop] = useGlobal('cookieShop');

    // Get heading height
    const headingRef = React.useRef(null);
    const [, headingHeight] = useResizeObserver(headingRef);

    // SKU
    const sku =
        product.variations && product.variations.length > 0
            ? product.variations[0].sku
            : product.sku;

    // Swell product (simple)
    const swellProduct = allSwellProducts[sku] ?? {};

    return (
        <>
            <Header {...{ state }} ref={headingRef}>
                <Category>
                    {overwrittenSubtitle
                        ? overwrittenSubtitle
                        : labels.CATEGORIES[product.model.apiKey.toUpperCase()]}
                </Category>
                <h1>{product.name}</h1>
                <Line />
            </Header>
            <Content>
                <Details {...{ state, headingHeight }}>
                    <Price>
                        {`${swellProduct?.price} ${labels.CURRENCY[currency]}`}
                    </Price>
                    <Description>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: product.description,
                            }}
                        />
                    </Description>
                    <Buttons>
                        <Button
                            {...{
                                onClick() {
                                    leaveScrollingMode();
                                    transitionNavigate({
                                        from: location,
                                        to: localizeLink(
                                            `/shop/${product.slug}/`,
                                            locale
                                        ),
                                        transition: [
                                            'default',
                                            {
                                                theme,
                                            },
                                        ],
                                    });
                                },
                                theme,
                                type: 'secondary',
                                label: labels.PAGES.HOME.DISCOVER,
                                style: ButtonStyle,
                                arrow: true,
                            }}
                        />
                        <Button
                            {...{
                                async onClick() {
                                    // Get SKU
                                    const swellProduct =
                                        product.variations &&
                                        product.variations.length > 0
                                            ? allSwellProducts[
                                                  product.variations[0].sku
                                              ]
                                            : allSwellProducts[product.sku];

                                    await addItem({
                                        id: swellProduct?.id,
                                        quantity: 1,
                                        metadata:
                                            cartUtils.getMetadata(product),
                                    });
                                },
                                loading,
                                theme,
                                disabled: !cookieShop,
                                label: labels.PAGES.HOME.BUY,
                                feedback: labels.PAGES.HOME.BOUGHT,
                                style: ButtonStyle,
                            }}
                        />
                    </Buttons>
                </Details>
                <ImageWrapper {...{ headingHeight }}>
                    <Image
                        {...{
                            source: product.thumbnail.fluid,
                            alt: product.name,
                        }}
                    />
                </ImageWrapper>
            </Content>
        </>
    );
};

const Header = styled.div`
    ${s.grid.columns('width', {
        0: 4,
        20: 6,
        30: 12,
    })};

    ${s.responsive.property('top', {
        0: 'calc(42% - 25vh)',
        20: 'calc(42% - 20vh)',
    })};

    position: absolute;

    h1 {
        ${s.fonts.style('h1')};
        position: relative;
        z-index: ${s.layout.zIndex.promotionalProductsHeading};
        margin-bottom: 0.15em;
        ${props => ItemTransform(props)};
    }

    > div {
        ${props => ItemTransform(props)};
    }
`;

const Category = styled.div`
    ${s.layout.sectionTitle()};
`;

const Line = styled.div`
    width: 100%;
    background: currentColor;
    height: 1px;
    position: relative;
    z-index: ${s.layout.zIndex.promotionalProductsImage} - 1;
`;

const Content = styled.div`
    ${s.grid.columns('width', {
        0: 12,
    })};

    ${s.responsive.property('flex-direction', {
        0: 'column',
        20: 'row',
    })};

    display: flex;
    flex-wrap: nowrap;
`;

const Details = styled.div`
    ${s.grid.columns('width', {
        0: 4,
        20: 3,
        30: 5,
        40: 4,
    })};

    p {
        ${s.grid.columns('width', {
            20: 2.4,
            30: () => '100%',
        })};
    }

    ${s.responsive.mq(20)} {
        top: ${props => `calc(42% - 20vh + ${props.headingHeight}px + 150px)`};
        transform: translateY(-50%);

        position: absolute;
    }

    ${s.responsive.property('order', {
        0: 2,
        20: 1,
    })};

    > div {
        ${props => ItemTransform(props)};
    }
`;

const Price = styled.div`
    ${s.fonts.style('h9')};

    ${s.responsive.property('margin-top', {
        20: '1em',
    })};

    ${s.responsive.property('margin-bottom', {
        0: '0.5em',
        20: '1em',
    })};
`;

const Description = styled.div`
    ${s.responsive.property('display', {
        0: 'none',
        20: 'block',
    })};

    ${s.responsive.property('margin-bottom', {
        0: '1.5em',
        20: '2em',
        30: '3em',
        40: '4em',
    })};

    p {
        ${s.fonts.style('h11')};
    }
`;

const Buttons = styled.div`
    display: flex;

    ${s.responsive.property('justify-content', {
        0: 'space-between',
        20: 'flex-start',
        30: 'space-between',
    })};
`;

const ButtonStyle = css`
    ${s.grid.columns('width', {
        0: 2,
        20: 1.4,
        30: 2.5,
        40: 2,
    })};

    &:last-of-type {
        ${s.grid.gutter('margin-left', {
            20: 2,
            30: 0,
        })};
    }
`;

const ImageWrapper = styled.div`
    ${s.grid.columns('width', {
        0: 4,
        20: 3,
        30: 7,
    })};

    ${s.grid.columns('margin-left', {
        0: [0, 0],
        20: [3, 2],
        30: [5, 2],
        40: [5, 2],
    })};

    ${s.responsive.property('order', {
        0: 1,
        20: 2,
    })};

    ${s.responsive.property('margin-top', {
        0: '2vh',
        10: '6vh',
    })};

    position: relative;
    z-index: ${s.layout.zIndex.promotionalProductsImage};

    ${s.responsive.mq(30)} {
        margin-top: 0;
        top: ${props => `calc(42% - 20vh + ${props.headingHeight}px - 270px)`};

        position: absolute;
    }
`;

const ItemTransform = props => css`
    will-change: transform;
    transform: translateY(10vh);
    transition: transform 0.7s ${s.easings.smooth.out}, opacity 0.5s;
    opacity: 0;

    /* active */
    ${props.state.active &&
    css`
        opacity: 1;
        transform: translateY(0);
    `}

    /* previous */
    ${props.state.previous &&
    css`
        transform: translateY(-10vh);
    `}
`;

export default PromotionalProductComponentInner;
